import React from 'react'
import catAnimation from '../assets/catAnimation.gif'
import {FaCat} from 'react-icons/fa'

function PlaceHolder() {
  // const inviteLink = 'https://discord.com/api/oauth2/authorize?client_id=1023285820213444669&permissions=17180467248&scope=applications.commands%20bot'
  const inviteLink =  'https://discord.com/api/oauth2/authorize?client_id=1023285820213444669&permissions=599056&scope=bot'

  return (
      <div className='pt-8 px-5 sm:px-12 lg:px-24 xl:px-64 xl:max-w-96 '>
          <h1 className='text-4xl pb-4 font-medium text-white'>Get started</h1>
     
                <div className='text-white text-opacity-80'>
                  <h1 className='text-lg text-opacity-90 pb-1'>1. <a className='text-purple-500 hover:text-green-500 pr-2 underline' href={inviteLink} target="_blank" rel="noopener noreferrer" >Add Parker</a>to your server (available only for managers).</h1>
                  <h1 className='text-lg text-opacity-90 pb-1'>2. Copy & paste your server ID, <a className='text-purple-500 underline hover:text-green-500' href='https://www.alphr.com/discord-find-server-id/' target="_blank" rel="noopener noreferrer"> use this guide.</a></h1>
                  <h1 className='text-lg text-opacity-90 pb-1 flex'>3. Let Parker create magic.<span className='px-2 text-center pt-1'><FaCat/></span></h1>
                <div className='py-6 object-cover'>

                  <iframe
                    src='https://www.loom.com/embed/a8b1e9fe0bce472a92ed42d1670e235f'     
                    frameBorder='0'
                    allow='autoplay encrypted-media'
                    allowFullScreen
                    title='video'
                    className="rounded-md border border-purple-600  border-opacity-40 h-80  w-full xl:w-[500px] md:w-[400px]" />
          

          </div>
        </div>
    </div>
  )
}

export default PlaceHolder


