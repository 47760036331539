import React, { useEffect, useState } from 'react'
import { useGuildContext } from '../hooks/useGuildContext'
import axios from '../config/axios'
import UsersCounter from '../components/dash/UsersCounter'
import MessageCounter from '../components/dash/MessagesCounter'
import catAnimation from '../assets/catAnimation.gif'

function Dash() {
  const {guild} = useGuildContext()
  const [selectedChannel, setSelectedChannel] = useState()
  const [sortedUsers, setSortedUsers] = useState(null)
  const [sortedMessages, setSortedMessages] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isStart, setIsStart] = useState(false)

  useEffect(() => { 
    const settingData = async () => { 
      guild.map( async (channel, index) => { 
        if(channel.name && channel.name === selectedChannel) { 
            const channelMessages = await axios.post('/api/channelData', {
            data: channel.id
          })
          const localData = await channelMessages.data;
          setSortedUsers(Object.entries(localData.hotUsers).sort((a, b) => { 
            return b[1] - a[1]
          }))

          setSortedMessages(Object.entries(localData.messageCounter).sort((a, b) => { 
            return b[1] - a[1]
          }))      
        }
        setIsLoading(false)
      })
    }
    settingData()
    setIsLoading(true)
  }, [guild, selectedChannel])

  useEffect(() => { 
    setIsLoading(false)
    setIsStart(true)
  }, [])



  return (
      <div className='pt-8 px-5 sm:px-12 lg:px-24 xl:px-64 xl:max-w-96 sm:pt-12  pb-24 bg-[#060A17]'>

        <div className='items-end sm:flex xl:row-span-1 sm:gap-8 pb-4 '> 
            <div className='pt-8 xl:pt-0  '>
              <span className='text-2xl text-opacity-70 text-white'>Server</span> 
              <h1 className=' text-4xl text-white font-medium '>{guild[0].guildName}</h1>
            </div>
        <div className=' pt-4 sm:pt-0'>
              <select 
              defaultValue=''
              className=' -ml-1 sm:pl-2 sm:w-36 rounded-xl text-green-500 hover:cursor-pointer hover:text-purple-500 bg-blue-600  border-blue-600 bg-opacity-0  ' 
              onChange={(e) => {
              setSelectedChannel(e.target.value)
              }}> 
               <option value="" disabled>
                Pick a channel
                </option>
              {guild && guild.map((channel) => {
                return channel.name ? <option key={channel.id}>{channel.name}</option> : null
              })}
              
          </select>
            </div>


        </div>
    
        <div className='gap-8 grid'>
        {/* Hot User */}
        <div className='bg-blue-500 bg-opacity-10 row-span-1 text-white py-8 rounded-xl'>
        <h1 className='text-2xl font-normal px-8 pb-8 '><span className='pr-2 text-5xl'>👩🏻‍🎤</span>Most engaged members</h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 2xl:grid-cols-5 gap-3 px-8'> 
              {isLoading ? 
                <div className='flex gap-4 items-end'>
                  <img src={catAnimation} alt='loading animation'/> 
                  <h1 className=' pb-1 text-opacity-60 text-white'>Parker's turn...</h1>
                </div> : sortedUsers ?  sortedUsers.map((key, index) => { 
                  if(key[1] > 5) { 
                      return <UsersCounter name={key[0]} count={key[1]} key={index}/>
                  }
                }) : isStart ? <></> : <h1 className='w-max text-white text-opacity-70'>Data is not available for this channel</h1>
              }
            </div>
          </div>

          {/* Content Counter */}
          <div className='bg-blue-500 bg-opacity-10  row-span-1 text-white  rounded-xl py-8  '>
            <h1 className='text-2xl font-normal px-8 pb-8'><span className='pr-4 text-5xl'>💬</span>Most common messages</h1>
            <div className={isLoading ? 'grid grid-cols-1 gap-3 px-8 ' : 'grid grid-cols-1 sm:grid-cols-2  md:grid-cols-4 xl:grid-cols-5 gap-3 px-8'}> 
              {
              isLoading ? 
                <div className='flex gap-4 items-end'>
                <img src={catAnimation} alt='loading animation'/> 
                <h1 className=' pb-1 text-opacity-60 text-white'>Parker's turn...</h1>
                </div> : sortedMessages ? sortedMessages.map((key, index) => { 
                  if(key[1] > 5 ) { 
                    return <MessageCounter message={key[0]} count={key[1]} key={index}/>
                  }
                }) : isStart ? <></> : <h1 className='w-max text-white text-opacity-70'>Data is not available for this channel</h1>}
            </div>
          </div>

        </div>
        
      </div>
    )
}

export default Dash



