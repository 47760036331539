import React from 'react'

const MessageCounter = ({message, count, index}) => {

  return (
    <div>
        <div className='flex gap-2 border rounded-full py-2 place-content-between px-5 bg-blue-60 bg-blue-900 bg-opacity-5 '>
            <h1 className='text-white'>{message}</h1> 
            <h1 className='text-green-500 font-bold'> {count}</h1> 
        </div>
    </div>
  )
}

export default MessageCounter