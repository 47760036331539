import React from 'react'

const Footer = () => {
    
    return ( 
    
<footer className="p-4 bg-[#050914] text-white shadow md:flex md:items-center md:justify-between md:p-6 px-5 sm:px-12 lg:px-24 xl:px-64 xl:max-w-96">
    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 The Cat Parker| All Rights Reserved.
    </span>
    <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <a href="mailto:amitay1599@gmail.com" className="hover:underline">amitay1599@gmail.com</a>
        </li>
    </ul>
</footer>

    )
}

export default Footer;


