import './App.css';
import Home from './pages/Home';
import ReactGA from 'react-ga';
import analytics from './utils/analytics';

const App = () => {

  // ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
  ReactGA.initialize('UA-249673686-1');
  // analytics.init()
  // analytics.sendPageview()
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
  
      <div className='h-screen bg-[#0B152D]'>
        <Home/>
    </div>
     
  );
}

export default App;
