import { createContext, useReducer } from "react";

export const GuildContext = createContext();

export const guildReducer = (state, action) => { 
    switch(action.type) { 
        case 'SET_GUILD': 
        return { 
            guild: action.payload
        }
        default: 
        return state 
    }
}


export const GuildContextProvider = ({children}) => { 
    const [state, guildeDispatch] = useReducer(guildReducer, {
        guild: null
    })
    
    //   console.log('guildContext state:', state)
    return ( 
        <GuildContext.Provider value={{...state, guildeDispatch}}> 
        {children}
        </GuildContext.Provider>
    )
}
 
