import Dash from './Dash';
import PlaceHolder from '../components/PlaceHolder';
import { useGuildContext } from '../hooks/useGuildContext';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Home  =  () => {
  const {guild} = useGuildContext()

  return (
    <div>
    <Header/>
        <div className=' bg-[#060A17] min-h-screen h-max'>
          <Hero />
          {guild ? <Dash/> : <PlaceHolder />}
        </div>
      <Footer />
  </div>
  )
}

export default Home;




