import { useContext } from 'react';
import { GuildContext } from '../context/guildContext';



export const useGuildContext = () => { 
    const context = useContext(GuildContext);
    if(!context){ 
        throw Error('cant use useGuildContext outside of scope')
    }
    return context
}


