import React, { useEffect, useState } from 'react'
import axios from '../config/axios'
import { useGuildContext } from '../hooks/useGuildContext'
import analytics from '../utils/analytics'
import ReactGA from 'react-ga';



function Form() {
    const [id, setId] = useState('')
    const [error, setError] = useState(false)
    const [authError, setAuthError] = useState(false)
    const {guild, guildeDispatch} = useGuildContext()

    const inviteLink = 'https://discord.com/api/oauth2/authorize?client_id=1023285820213444669&permissions=17180467248&scope=applications.commands%20bot'


    // useEffect(() =>{ 
    //     ReactGA.initialize('UA-249673686-1');
    //     console.log('Initializing!')
    // },[])

    const handleSubmit = async (e) => { 
        e.preventDefault()
        await analytics.eventTrack('Users','EnterId', '555')
        // await ReactGA.event({
        //     category: 'Scroll',
        //     action: 'Scrolled to heading 2',
        //     value: id
        //   })
        
        if(id.length < 18 || id.length > 19) { 
            setError(true)
            console.log('error!')
        } else { 
        //Fetching data   
        const response = await axios.post('/api/guildData', {
            data: id
        })

        if(response.data.guildData === 'Guild Error')    { 
            console.log('yes')
            setError(true)
            setId('')
            } else  { 
                setAuthError(false)
                setError(false)
                const data = response.data.guildData
                guildeDispatch({type: 'SET_GUILD', payload: data,})
                setId('')
            }
        }
}


    return (
        <div className='pt-12 xl:pt-16 sm:pt-0'>
            <form onSubmit={handleSubmit}>
                <div className='flex-col '>

                    {/* 1 */}
                    <div  className='sm:flex gap-2 center  '>
                        <div className='items-center pb-4 sm:pb-0 flex ' >
                
                            <input 
                            required 
                            onChange={(e) => setId(e.target.value)} 
                            placeholder='Enter server ID'
                            type='password'
                            className='text-blue-100 placeholder:text-blue-100 placeholder:text-opacity-60  border border-green-500 bg-green-500  bg-opacity-10  rounded-full py-[10px] pl-7 pr-8 w-full'/>
                        </div>
        
                        {/* 2*/}
                        <button type='submit' className=' rounded-full bg-green-600 font-medium hover:bg-purple-600 px-6 w-full sm:w-fit py-[10px]'>Run Parker!</button>
                    </div>
                    <div className='grid gap-2 py-4'>
                    {error ?  <h1 className='text-blue-500 break-words '>Not a vaild ID number.<br/>Pls enter a server ID that has Parker in it <a className='text-purple-500 underline' href='https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-' target="_blank" rel="noopener noreferrer"> this guid.</a></h1>  : <div></div>}
                    {authError ? <h1 className='text-blue-500 '>You don't have permission for this server, <a className='text-green-500 underline' href={inviteLink} target="_blank" rel="noopener noreferrer">add Parker!</a></h1> : <div></div>}
                    </div>
                </div>
            </form>
    
        </div>
      )
   
  
}

export default Form





