import React from 'react'
import faceCat from '../assets/faceCat.gif'


const Header = () => { 
    return ( 
<footer className="p-6 bg-[#050914] bg-opacity-0 text-white shadow  px-5 sm:px-12 lg:px-24 xl:px-64 xl:max-w-96 ">
    <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0 place-content-between ">
        <li className='flex'>
        <a  href="https://thecatparker.com/" ><img src={faceCat}  alt='parker' className=''/> </a>
            <a href="https://thecatparker.com/" className="hover:text-purple-600 self-center px-2">The Cat Parker</a>
        </li>
        <li>
            <a href="mailto:amitay1599@gmail.com" className="hover:underline hidden sm:block">amitay1599@gmail.com</a>
        </li>
    </ul>
</footer>

    )
}

export default Header;