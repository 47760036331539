import React from 'react'
import Form from './Form'
import {FaCat} from 'react-icons/fa'

function Hero() {

  const inviteLink = 'https://discord.com/api/oauth2/authorize?client_id=1023285820213444669&permissions=17180467248&scope=applications.commands%20bot'


  return (
    <div className=' md:pt-2 px-5 sm:px-12 lg:px-24 xl:px-64 col-span-3 bg-[#0B152D] h-full pb-2'>
       <div className=''>
        <div className='flex pt-4 sm:pt-6   gap-2  xl:gap-0 items-center align-middle '> 
          <h1 className='text-4xl sm:text-5xl xl:text-6xl 2xl:text-7xl text-white '>Discover what your Discord <br className='hidden md:block'/> community is up to.</h1>
          </div>

          <div className='flex-col pt-4 sm:pb-4'>
            <h1 className=' text-xl lg:text-2xl  pb-4 font-light font-sans text-opacity-60 text-white '>Parker reveals what are the hottest topics & who are the most active <br className='hidden md:block'/> members in your channel (last 500 messages analysis).</h1>
            {/* <a target="_blank" rel="noopener noreferrer"  href={inviteLink} className='text-green-500  hover:text-purple-600 '>Add Parker<span className='px-2 '><FaCat className='inline pb-[7px]' size={25}/></span></a>    */}
          </div>
        </div>

        <Form />    
    </div>

  )
}

export default Hero